import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import List from "../../../components/custom-widgets/list.js";
import BestBanksDefault from "../../../components/best-banks/best-banks-default.js";

// Helper Functions
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";

const PaymentProcessingServices = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/business-banking/business-services/payment-processing-services/hero-payment-processing-services-102623-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/business-banking/business-services/payment-processing-services/hero-payment-processing-services-102623-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/business-banking/business-services/payment-processing-services/hero-payment-processing-services-102623-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/business-banking/business-services/payment-processing-services/hero-payment-processing-services-102623-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/business-banking/business-services/payment-processing-services/hero-payment-processing-services-102623-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/business-banking/business-services/payment-processing-services/hero-payment-processing-services-102623-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/business-banking/business-services/payment-processing-services/hero-payment-processing-services-102623-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
      imgContactlessPayment: file(relativePath: { eq: "merchant-services-contactless-payment.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, width: 600, height: 400, layout: CONSTRAINED)
        }
      }
    }
  `);

  const heroChevronData = {
    id: "business-services-payment-processing-services-hero",
    ...getHeroImgVariables(imgData),
    altText: "Woman checking out at vegetable stand with a mobile phone.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Accept Payments Anywhere 24/7 on Any Device"
          }
        },
        {
          id: 2,
          button: {
            id: "merchant-services-payment-processing-hero-cta",
            text: "Get Started Today",
            url: "https://go.heartland.us/wafd#form",
            class: "btn-white",
            externalLink: true,
            target: "blank"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/business-banking",
      title: "Business Banking"
    },
    {
      id: 2,
      url: "/business-banking/business-services",
      title: "Business Services"
    },
    {
      id: 3,
      active: true,
      title: "Payment Processing Services"
    }
  ];

  const seoTitle = "Payment Processing for Small Business";
  const seoDesc =
    "Find out how your small business can accept payments anywhere using the most up to date payment processing technology with Heartland Payments at WaFd Bank.";
  const SEOData = {
    title: seoTitle,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: seoTitle
      },
      {
        name: "description",
        content: seoDesc
      },
      {
        property: "og:title",
        content: seoTitle
      },
      {
        property: "og:description",
        content: seoDesc
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/business-banking/business-services/payment-processing-services"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-payment-processing-services-102623.jpg"
      }
    ]
  };

  const HeartlandGetStartedButton = (id) => (
    <a
      href="https://go.heartland.us/wafd#form"
      id={id}
      target="_blank"
      rel="noopener noreferrer"
      className="btn btn-link no-min-width w-100 w-sm-auto"
    >
      Get Started
    </a>
  );

  const integratedPaymentsListData = {
    accountName: "integrated-payments",
    items: [
      {
        id: 1,
        text: "<strong>Take payments anywhere:</strong> Online, restaurant, retail, and on the go"
      },
      {
        id: 2,
        text: "<strong>Accept any major method of payment:</strong> Cash, check, credit, debit and gift cards, chip card and digital wallet"
      },
      {
        id: 3,
        text: "<strong>Process payments over any compatible device:</strong> Mobile phone, tablet, laptop, terminal and point of sale system"
      }
    ]
  };

  const mobilePayListData = {
    accountName: "mobile-pay",
    items: [
      {
        id: 1,
        text: "Run your business from your phone"
      },
      {
        id: 2,
        text: "Go where customers are"
      },
      {
        id: 3,
        text: "Never miss a sale"
      },
      {
        id: 4,
        text: "Capture customer trends"
      }
    ]
  };

  const ecommerceListData = {
    accountName: "ecommerce",
    items: [
      {
        id: 1,
        text: "Full-service platform"
      },
      {
        id: 2,
        text: "Set up your own online portal"
      },
      {
        id: 3,
        text: "Sell and buy"
      },
      {
        id: 4,
        text: "Collect payments"
      },
      {
        id: 5,
        text: "Combine gateway and payment processing"
      },
      {
        id: 6,
        text: "Securely accept orders and payments"
      }
    ]
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />

      <section className="container" id="payment-processing-heading-section">
        <div className="row">
          <div className="col">
            <h1>Payment Processing Services</h1>
            <h3>
              WaFd Bank has partnered with Heartland to help your business with an extensive, flexible suite of
              solutions, tailored to meet your individual needs. Accept payments anywhere with the most up-to-date
              technology: online, mobile, through a kiosk, at the counter, contactless and in the field.
            </h3>
            <h3 className="text-success font-weight-semi-bold">
              Sign up to use Heartland with your WaFd business account and get a five-year price lock!
            </h3>
            <a
              href="https://go.heartland.us/wafd#form"
              className="btn btn-primary"
              target="_blank"
              rel="noopener noreferrer"
              id="heartland-get-started-cta"
            >
              Get Started
            </a>
          </div>
        </div>
      </section>
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3 mb-md-0">
              <StaticImage
                quality="100"
                placeholder="blurred"
                alt="Heartland integrated payments dashboard on a monitor and mobile phone."
                src="../../../images/thumbnail-integrated-payments-050824.jpg"
              />
            </div>
            <div className="col-md-6">
              <h2 className="font-weight-semi-bold">Integrated Payments</h2>
              <p>
                You'll be able to accept payments anywhere, anytime, on any device. Our payment solutions integrate
                seamlessly with most{" "}
                <Link to="/business-banking/business-services/pos-and-inventory-systems" id="point-of-sale-page-link">
                  point-of-sale
                </Link>{" "}
                systems. We'll set up your terminal and get you up and running.
              </p>
              <List {...integratedPaymentsListData} />
              <HeartlandGetStartedButton id="heartland-integrated-payments-get-started-cta" />
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="row">
          <div className="col-md-6 order-2 order-md-1">
            <h2 className="font-weight-semi-bold">Mobile Pay</h2>
            <p>
              Whether your business is always on the go or you need an extra payment terminal in your storefront, Mobile
              Pay has the tools you need. Mobile Pay's app and reader accept chip cards, contactless (Apple Pay, Google
              Pay, Samsung Pay), magstripe, cash and checks, and are compatible with iOS and Android phones and tablets.
            </p>
            <List {...mobilePayListData} />
            <HeartlandGetStartedButton id="heartland-mobile-pay-get-started-cta" />
          </div>
          <div className="col-md-6 order-1 order-md-2 mb-3 mb-md-0">
            <StaticImage
              quality="100"
              placeholder="blurred"
              alt="Man holding a credit card making a mobile phone payment."
              src="../../../images/thumbnail-online-shopping-050824.jpg"
            />
          </div>
        </div>
      </section>
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3 mb-md-0">
              <StaticImage
                quality="100"
                placeholder="blurred"
                alt="Making a mobile payment using a watch and Heartland hardware."
                src="../../../images/thumbnail-mobile-payment-050824.jpg"
              />
            </div>
            <div className="col-md-6">
              <h2 className="font-weight-semi-bold">Ecommerce</h2>
              <p>
                Shopping cart? We make integration a breeze with pre-built plug-ins for more than 550 major technology
                platforms and 80 percent of popular shopping carts. No programming experience is required to start
                testing your application within Heartland's developer sandbox in as little as 5 minutes.
              </p>
              <List {...ecommerceListData} />
              <HeartlandGetStartedButton id="heartland-ecommerce-get-started-cta" />
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="row">
          <div className="col-sm-6 col-md-4">
            <h4 className="font-weight-semi-bold text-success">Bill Payment</h4>
            <p>
              We'll help you select and integrate the right bill payment solution for you. You'll also be able to access
              consolidated reporting across all payment channels and methods.
            </p>
          </div>
          <div className="col-sm-6 col-md-4">
            <h4 className="font-weight-semi-bold text-success">Pricing</h4>
            <p>
              With our interchange optimization service, you qualify for the best rates. Save with cost reductions and
              put more money in your pocket to minimize payment acceptance costs. Plus, flexible funding options to get
              payments in your hands sooner.
            </p>
          </div>
          <div className="col-sm-6 col-md-4">
            <h4 className="font-weight-semi-bold text-success">Safe and Secure Transactions</h4>
            <p>
              Heartland Secure is the most secure card processing solution in the payments industry, backed by a
              comprehensive warranty. We use three powerful technologies&mdash;EMV, end-to-end encryption and
              tokenization&mdash;to protect your customers' card data to help you avoid costly data breaches.
            </p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-sm-6 col-md-4">
            <a
              href="https://go.heartland.us/wafd#form"
              className="btn btn-primary btn-block mb-4"
              target="_blank"
              rel="noopener noreferrer"
              id="heartland-pricing-get-started-cta"
            >
              Get Started
            </a>
          </div>
        </div>
      </section>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default PaymentProcessingServices;
